import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137')
];

export const server_loads = [0,41,17,42,19,20,26,2,9,3,33,35,36,12,5,7,4];

export const dictionary = {
		"/(landings)/(home)": [~129,[41]],
		"/(hub)/admin": [73,[17]],
		"/(hub)/admin/impersonation": [~74,[17]],
		"/(hub)/admin/stats": [75,[18]],
		"/(hub)/admin/stats/teams": [~76,[18]],
		"/(iam)/auth/edu": [~121,[39]],
		"/(iam)/auth/invite": [~122],
		"/(iam)/auth/password": [~123],
		"/(iam)/auth/recover": [~124,[38]],
		"/(iam)/auth/signin": [~125,[38]],
		"/(iam)/auth/signup": [~126,[38]],
		"/(landings)/championship": [~130,[42]],
		"/(landings)/championship/disciplines": [131,[42]],
		"/(landings)/championship/documents": [132,[42]],
		"/(hub)/console": [77,[19]],
		"/(hub)/console/cms/[resource_id]/richtext": [78,[20]],
		"/(hub)/console/cms/[resource_id]/text": [~79,[21]],
		"/(hub)/console/codes": [~80,[19]],
		"/(hub)/console/codes/[id]": [~81,[22]],
		"/(hub)/console/crm/feedback": [~82,[19]],
		"/(hub)/console/crm/partnership": [~83,[19]],
		"/(hub)/console/crm/subscription": [~84,[19]],
		"/(hub)/console/organizations": [~85,[19]],
		"/(hub)/console/organizations/[id]": [~86,[23]],
		"/(hub)/console/organizations/[id]/groups": [~87,[23]],
		"/(hub)/console/organizations/[id]/groups/[group_id]": [~88,[24]],
		"/(hub)/console/organizations/[id]/groups/[group_id]/roles": [~89,[24]],
		"/(hub)/console/organizations/[id]/groups/[group_id]/terms": [~90,[24]],
		"/(hub)/console/organizations/[id]/roles": [~91,[23]],
		"/(hub)/console/terms": [~92,[19]],
		"/(hub)/console/terms/[id]": [~93,[25]],
		"/(hub)/console/terms/[id]/map": [94,[25]],
		"/(hub)/console/users": [~95,[19]],
		"/(hub)/dev": [96,[26],[27]],
		"/(hub)/dev/examples": [~97,[28]],
		"/(hub)/dev/examples/await": [98,[28]],
		"/(hub)/dev/examples/scheduler": [99,[28]],
		"/(hub)/dev/examples/seaweedfs": [100,[28]],
		"/(hub)/dev/examples/xapi": [101,[28]],
		"/(hub)/dev/examples/yjs": [102,[28]],
		"/(hub)/dev/landing": [103,[29]],
		"/(hub)/dev/playground": [~104,[30]],
		"/(hub)/dev/playground/buttons": [105,[30]],
		"/(hub)/dev/playground/confirm": [106,[30]],
		"/(hub)/dev/playground/dates": [107,[30]],
		"/(hub)/dev/playground/inputs": [~108,[30]],
		"/(hub)/dev/playground/modal": [109,[30]],
		"/(hub)/dev/playground/popover": [110,[30]],
		"/(hub)/dev/playground/switch": [111,[30]],
		"/(hub)/dev/playground/tabs copy": [113,[30]],
		"/(hub)/dev/playground/tabs": [112,[30]],
		"/(hub)/dev/playground/toasts": [114,[30]],
		"/(education)/events": [57,[8]],
		"/(landings)/faststart": [133],
		"/(education)/groups": [~58,[2]],
		"/(education)/groups/[id]": [~59,[9]],
		"/(education)/groups/[id]/terms": [~60,[9]],
		"/(education)/(home)/home": [~44,[3]],
		"/(education)/(home)/invitations_international": [~46,[3]],
		"/(education)/(home)/invitations_limited": [~47,[3]],
		"/(education)/(home)/invitations": [~45,[3]],
		"/legal/user-agreement": [137,[43]],
		"/(hub)/lms": [115,[31],[32]],
		"/(hub)/lms/[type=blueprint_type]": [~116,[31],[32]],
		"/(hub)/lms/[type=blueprint_type]/[root_id]/[[nested_id]]/content": [118,[33,35,36],[34]],
		"/(hub)/lms/[type=blueprint_type]/[root_id]/[[nested_id]]/materials": [119,[33,35],[34]],
		"/(hub)/lms/[type=blueprint_type]/[root_id]/[[nested_id]]": [~117,[33,35],[34]],
		"/(hub)/mentorship": [120,[37]],
		"/(landings)/opencup": [~134],
		"/(landings)/opencup/disciplines": [135],
		"/(landings)/opencup/documents": [136],
		"/(education)/org/[org_id]": [~61,[2]],
		"/(education)/org/[org_id]/events": [63,[11]],
		"/(education)/org/[org_id]/events/[term_id]/teams": [~64,[12]],
		"/(education)/org/[org_id]/events/[term_id]/teams/personal": [~66,[12]],
		"/(education)/org/[org_id]/events/[term_id]/teams/team": [67,[12]],
		"/(education)/org/[org_id]/events/[term_id]/teams/[group_id]": [~65,[12],[,13]],
		"/(education)/org/[org_id]/teams/[group_id]": [~68,[2]],
		"/(education)/org/[org_id]/[slug]": [~62,[10]],
		"/(external)/platform/alg": [~71,[15]],
		"/(external)/platform/standings/[standing_id]": [72,[16]],
		"/(education)/rewards": [~69,[2]],
		"/(education)/rewards/[reward_id]": [~70,[2],[,14]],
		"/(iam)/user": [~127,[40]],
		"/(iam)/user/security": [~128,[40]],
		"/(education)/[type=term_type]": [~48,[2]],
		"/(education)/[type=term_type]/international": [~52,[5]],
		"/(education)/[type=term_type]/international/events": [53,[6]],
		"/(education)/[type=term_type]/international/events/[term_id]/teams": [~54,[7]],
		"/(education)/[type=term_type]/international/events/[term_id]/teams/[group_id]": [~55,[7]],
		"/(education)/[type=term_type]/international/teams/[group_id]": [~56,[5]],
		"/(education)/[type=term_type]/[slug]": [~49,[4]],
		"/(education)/[type=term_type]/[slug]/teams/[group_id]": [~51,[4]],
		"/(education)/[type=term_type]/[slug]/[blueprint_id]": [~50,[4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.js';